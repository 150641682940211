import * as React from 'react'
import Popup from 'reactjs-popup'
import { DepartmentShape, EmailTemplateShape } from '../../../api/api_data_types'
import ReminderForm from './ReminderForm'

interface SendReminderButtonProps {
  department: DepartmentShape
  week: number
  year: number
  templates: EmailTemplateShape[]
}

export default function SendReminderButton({ department, templates }: SendReminderButtonProps) {
  return (
    <Popup
      trigger={
        <button type="button" className={'btn btn-outline-secondary'}>
          Send påmindelse
        </button>
      }
      position="center center"
      contentStyle={{ width: '100%', maxWidth: '720px', maxHeight: '100%', overflowX: 'hidden', overflowY: 'auto' }}
      nested
      modal
    >
      {close => (
        <div className="resources-record-modal modal-popup">
          <a className="close" onClick={close}>
            &times;
          </a>
          <div className="popup-header">Send påmindelse - {department.title}</div>
          <ReminderForm department={department} templates={templates} close={close} />
        </div>
      )}
    </Popup>
  )
}
