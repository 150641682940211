import * as React from 'react'
import { RowData, Table } from '@tanstack/react-table'

export default function Paginator({ table }: { table: Table<RowData> }) {
  return (
    <div className="">
      <div className="d-flex justify-content-center">
        <button className="btn btn-outline-secondary mr-2" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
          {'<<'}
        </button>
        <button className="btn btn-outline-secondary mr-2" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          {'<'}
        </button>
        <button className="btn btn-outline-secondary mr-2" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          {'>'}
        </button>
        <button
          className="btn btn-outline-secondary mr-2"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
        <span className="pt-2">
          Side&nbsp;
          <strong>
            {table.getState().pagination.pageIndex + 1} af {table.getPageCount()}
          </strong>
        </span>
        <span className="d-flex ml-3">
          <span className="pt-2">Gå til side: </span>
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="form-control ml-2 pagination-go-to-page-input"
          />
        </span>
        <span className="d-flex ml-3">
          <span className="pt-2">Række pr. side: </span>
          <select
            className="form-control pagination-rows-per-page-select ml-1"
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </span>
      </div>
    </div>
  )
}
