import * as React from 'react'
import { useEffect, useReducer } from 'react'
import CategoryList from './components/CategoryList'
import PriorityList from './components/PriorityList'
import TagList from './components/TagList'
import VendorList from './components/VendorList'
import Search from './components/Search'
import Map from './components/Map'
import ModalWrapper from './components/ModalWrapper'
import TermsStore from './stores/TermsStore'
import I18nStore from './stores/I18nStore'
import VendorListStore from './stores/VendorListStore'

type VendorsDirectoryAppProps = {
  terms: []
  vendors: []
  locales: []
}
export default function VendorsDirectoryApp(props: VendorsDirectoryAppProps) {
  const { terms, vendors, locales } = props
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  useEffect(() => {
    TermsStore.load(terms)
    VendorListStore.load(vendors, TermsStore.getTerms())
    VendorListStore.setFilterByDefaultData()
    I18nStore.load(locales)
    const storeListener = VendorListStore.addListener('change', () => forceUpdate())
    forceUpdate()

    return () => {
      storeListener.remove()
    }
  }, [])

  if (VendorListStore.list.length) {
    return (
      <div className="vendor-directory-container">
        <div className="vendor-directory-categoryLabel">{I18nStore.t('categories')}</div>
        <div className="vendor-directory-headerContainer">
          <CategoryList />
          <Search />
        </div>
        <div className="vendor-directory-secondRowContainer">
          <div className="vendor-directory-leftFilterContainer">
            <PriorityList />
            <TagList />
          </div>
          <VendorList />
          <Map />
        </div>
        <ModalWrapper />
      </div>
    )
  } else {
    return <div>No Vendors</div>
  }
}
