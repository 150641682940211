import _ from 'lodash'
import React from 'react'
import VendorListStore from '../../stores/VendorListStore'
import ModalsStore from '../../stores/ModalsStore'
import MapStore from '../../stores/MapStore'

class Map extends React.Component {
  componentDidMount () {
    MapStore.createMap(this.container)
    MapStore.createCluster()
    window.addEventListener('scroll', this.handleScroll)
    this.forceUpdate()
  }

  shouldComponentUpdate () {
    if (!MapStore.map) { return false }
    if (MapStore.markers && MapStore.markers.length === VendorListStore.filteredList.length) { return false }
    return true
  }

  updateMarkers () {
    MapStore.clearMarkers()
    _.each(VendorListStore.filteredList, (vendor) => {
      vendor.marker = MapStore.createMarker(vendor)
      MapStore.addMarker(vendor.marker)
      vendor.marker.addListener('click', (e) => {
        ModalsStore.openModal('VendorInfoModal', {model: vendor})
      })
      vendor.marker.addListener('mouseover', (e) => {
        VendorListStore.highlight(vendor, true)
        vendor.setMarkerIcon('vendor_on')
      })
      vendor.marker.addListener('mouseout', (e) => {
        VendorListStore.highlight(vendor, false)
        vendor.setMarkerIcon('vendor_off')
      })
    })
    MapStore.syncMarkersWithCluster()
  }

  handleScroll () {
    // # TODO: use this handler to catch scroll state
    // console.log('eeeee', window.pageYOffset, this.cumulativeOffset(this.container))
  }

  render () {
    if (this.shouldComponentUpdate()) {
      this.updateMarkers()
      MapStore.fitMapToMarkers()
    }
    return (
      <div id="map" className="vendor-directory-map-container" ref={(container) => { this.container = container }} />
    )
  }
}

export default Map
