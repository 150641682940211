import _ from 'lodash'
import React from 'react'
import TermsStore from '../../stores/TermsStore'
import I18nStore from '../../stores/I18nStore'
import VendorListStore from '../../stores/VendorListStore'
import Priority from '../../components/Priority'

class PriorityList extends React.Component {
  render () {
    return (
      <div className="vendor-directory-priority-list-container">
        <div className="vendor-directory-priority-list-firstContainer">
          {this.renderTimes()}
          <strong>{I18nStore.t('priorities')}</strong>
        </div>
        {_.map(TermsStore.priorities, (priority) => {
          return <Priority key={priority.id} model={priority} />
        })}
      </div>
    )
  }

  renderTimes () {
    if (VendorListStore.filter.priorityIds.length) {
      return (
        <i onClick={this.clearFilter} className="fa fa-times vendor-directory-priority-list-clearAll" />
      )
    }
  }

  clearFilter () {
    VendorListStore.clearFilter('priorities')
  }
}

export default PriorityList
