import React from 'react'
import VendorListStore from '../../stores/VendorListStore'
import I18nStore from '../../stores/I18nStore'

class Search extends React.Component {
  change(e) {
    VendorListStore.setSearchText(e.currentTarget.value)
  }

  render() {
    return (
      <input
        className="search-input form-control vendors-directory-search-input"
        value={VendorListStore.filter.searchText}
        placeholder={I18nStore.t('search')}
        onChange={this.change}
      />
    )
  }
}

export default Search
