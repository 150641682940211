import React from 'react'
import ModalsStore from '../../stores/ModalsStore'
import MapStore from '../../stores/MapStore'
import I18nStore from '../../stores/I18nStore'
import Utils from '../../utils'

class Vendor extends React.Component {
  // propTypes: {
  //   model: React.PropTypes.object.isRequired
  // },

  openInfoModal () {
    ModalsStore.openModal('VendorInfoModal', {model: this.props.model})
  }

  render () {
    let model = this.props.model
    return (
      <div onMouseOver={() => this.selectMarker()}
           onMouseOut={() => this.deselectMarker()}
        className={`vendors-directory-vendor-container ${model.highlight ? 'highlight' : ''}`}>
        <div className="vendors-directory-vendor-title">{model.name}</div>
        <div>{model.contactPerson}</div>
        <div>{model.phone}</div>
        <div>{model.city}</div>
        <div className="vendors-directory-vendor-iconContainer">
          {this.renderPriorityIcon()}
          <i onClick={() => this.openInfoModal()} className="fa fa-info-circle" />
          {this.renderTarget()}
        </div>
      </div>
    )
  }

  renderPriorityIcon () {
    if (this.props.model.isPriority()) {
      return (
        <i title={I18nStore.t('Prioritized')} className="fa fa-star vendors-directory-vendor-star" />
      )
    }
    if (this.props.model.isApproved()) {
      return (
        <i title={I18nStore.t('Approved')} className="fa fa-check-circle vendors-directory-vendor-approved" />
      )
    }
  }

  renderTarget () {
    if (this.props.model.hasCoordinates()) {
      return (
        <i onClick={() => this.selectMarkerWithPosition()} className="fa fa-crosshairs" />
      )
    }
  }

  selectMarker () {
    let model = this.props.model
    MapStore.resetMarkerIcons()
    model.setMarkerIcon('vendor_on')
  }

  deselectMarker () {
    MapStore.resetMarkerIcons()
  }

  selectMarkerWithPosition () {
    let model = this.props.model
    this.selectMarker()
    MapStore.setCenter(model.getCoordinates())
    MapStore.setZoom(9)
    window.scrollTo(0, Utils.getVerticalMapPosition())
  }
}

export default Vendor
