import React from 'react'
import ModalsStore from '../../../stores/ModalsStore'

class VendorInfoModal extends React.Component {
  render () {
    return (
      <div className="tag-info-modal-container">
        <div><strong>{ModalsStore.data.model.name}</strong></div>
        <div className="tag-info-modal-description">{ModalsStore.data.model.description}</div>
        {this.renderImage()}
      </div>
    )
  }

  renderImage () {
    if (ModalsStore.data.model.image) {
      let sizes = this.getAppropriateSizes()
      return (
        <div className="tag-info-modal-imageContainer">
          <img width={sizes.width} height={sizes.height} src={ModalsStore.data.model.image.url} />
        </div>
      )
    }
  }

  getAppropriateSizes () {
    let image = ModalsStore.data.model.image
    let result = {
      height: image.height,
      width: image.width
    }
    if (image.height > window.innerHeight / 2) {
      result.height = window.innerHeight / 2
      result.width = image.width * result.height / image.height
    }
    return result
  }
}

export default VendorInfoModal
