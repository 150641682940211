import * as React from 'react'
import { useStorageState } from 'react-storage-hooks'
import WeekListTable from './WeekListTable'
import ShowWeekendsSwitcher from './ShowWeekendsSwitcher'
import FilterAndExportRow from './FilterAndExportRow'

interface WeekListAppProps {
  startDate: string
  endDate: string
  data: any[]
  availableDepartments: any[]
  selectedDepartmentIds: number[]
}

export default function WeekListApp({ startDate, endDate, data, availableDepartments, selectedDepartmentIds }: WeekListAppProps) {
  const [showWeekends, setShowWeekends] = useStorageState(sessionStorage, 'showWeekends', true)

  return (
    <>
      <FilterAndExportRow
        startDate={startDate}
        endDate={endDate}
        availableDepartments={availableDepartments}
        selectedDepartmentIds={selectedDepartmentIds}
      />
      <ShowWeekendsSwitcher showWeekends={showWeekends} setShowWeekends={setShowWeekends} />
      <WeekListTable startDate={startDate} endDate={endDate} showWeekends={showWeekends} data={data} />
    </>
  )
}
