import * as React from 'react'
import { da } from 'date-fns/locale'
import * as FocusTrap from 'focus-trap-react'
import { DayPicker, DateRange, SelectRangeEventHandler } from 'react-day-picker'
import { useRef, useState } from 'react'
import { usePopper } from 'react-popper'

interface RangePickerButtonProps {
  buttonLabel: string
  buttonClass: string
  range: DateRange
  setRange: (range: DateRange) => void
}

export default function RangePickerButton(props: RangePickerButtonProps) {
  const { buttonLabel, buttonClass, range, setRange } = props
  const [isPopperOpen, setIsPopperOpen] = useState(false)

  const popperRef = useRef(null)
  const buttonRef = useRef(null)
  const [popperElement, setPopperElement] = useState(null)

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom',
  })
  const closePopper = () => {
    setIsPopperOpen(false)
    buttonRef?.current?.focus()
  }
  return (
    <div>
      <div ref={popperRef}>
        <button
          ref={buttonRef}
          type="button"
          className={buttonClass}
          aria-label="Pick a date"
          onClick={() => {
            setIsPopperOpen(!isPopperOpen)
          }}
        >
          {buttonLabel}
        </button>
      </div>

      {isPopperOpen && (
        <div className="modal-window-overlay">
          <FocusTrap
            active
            focusTrapOptions={{
              initialFocus: false,
              allowOutsideClick: false,
              preventScroll: true,
              clickOutsideDeactivates: true,
              onDeactivate: closePopper,
              fallbackFocus: buttonRef.current,
            }}
          >
            <div
              tabIndex={-1}
              style={popper.styles.popper}
              className="datepicker-dialog d-block bg-white"
              {...popper.attributes.popper}
              ref={setPopperElement}
              role="dialog"
            >
              <DayPicker
                initialFocus={isPopperOpen}
                mode="range"
                locale={da}
                defaultMonth={range ? range.from : undefined}
                selected={range}
                showWeekNumber={true}
                weekStartsOn={1}
                fixedWeeks={true}
                showOutsideDays={true}
                ISOWeek={true}
                onSelect={setRange}
              />
              <div className="d-flex justify-content-between pb-3 pr-4 pl-4 pt-1">
                <button
                  className="btn btn-outline-secondary col-5"
                  type="button"
                  onClick={() => {
                    if (range.from === undefined) {
                      setRange({from: new Date(), to: range.to})
                    } else if (range.from > new Date()) {
                      setRange({ from: new Date(), to: range.to })
                    } else {
                      setRange({ from: range.from, to: new Date() })
                    }
                  }}
                >
                  i dag
                </button>
                <button className="btn btn-outline-secondary col-5" type="button" onClick={closePopper}>
                  ok
                </button>
              </div>
            </div>
          </FocusTrap>
        </div>
      )}
    </div>
  )
}
