import _ from 'lodash'
import React from 'react'
import VendorListStore from '../../stores/VendorListStore'
import ModalsStore from '../../stores/ModalsStore'

class Tag extends React.Component {
  // propTypes: {
  //   model: React.PropTypes.object.isRequired
  // }

  toggle (e) {
    VendorListStore.toggleFilterItem(this.props.model, 'tagIds', e.currentTarget.checked)
  }

  render () {
    return (
      <div className="vendors-directory-tag-container">
        <label>
          <input type="checkbox" onChange={(e) => this.toggle(e)} checked={this.getState()} />
          {this.props.model.name} ({this.props.model.amount})
          {this.renderInfo()}
        </label>
      </div>
    )
  }

  getState () {
    return _.includes(VendorListStore.filter.tagIds, this.props.model.id)
  }

  renderInfo () {
    if (this.props.model.description || this.props.model.image) {
      return (
        <i onClick={(e) => this.openInfoModal(e)} className="fa fa-info-circle vendors-directory-tag-info" />
      )
    }
  }

  openInfoModal (e) {
    ModalsStore.openModal('TagInfoModal', {model: this.props.model})
    e.preventDefault()
  }
}

export default Tag
