import * as React from 'react'
import { CurrentUserType } from '../DictionariesContext'
import { UserShape } from '../../api/api_data_types'
import { QueryClientProvider } from 'react-query'
import buildQueryClient from '../helpers/quieryClient'
import TravelDictionariesContext from './TravelDictionariesContext'
import TravelListTableWithFilter from "./TravelListTableWithFilter";

type TravelListAppProps = {
  currentUser: CurrentUserType
  availableUsers: UserShape[]
}

export default function TravelListApp({ currentUser, availableUsers }: TravelListAppProps) {
  const queryClient = buildQueryClient()
  const dictionaries = {
    currentUser,
    availableUsers,
  }
  return (
    <TravelDictionariesContext.Provider value={dictionaries}>
      <QueryClientProvider client={queryClient}>
        <TravelListTableWithFilter />
      </QueryClientProvider>
    </TravelDictionariesContext.Provider>
  )
}
