import * as React from 'react'
import { Travel } from './travel_list'
import EditTravelButton from './EditTravelButton'
import JoinTravelButton from './JoinTravelButton'
import LeaveTravelButton from './LeaveTravelButton'
import GoogleMapsPin from '../shared/GoogleMapsPin'
import useTravelRow from '../../hooks/useTravelRow'

type TravelListRowProps = {
  travel: Travel
}
export default function TravelListRow({ travel }: TravelListRowProps) {
  const { formattedDate, direction, joined, canJoin, availableSeats } = useTravelRow(travel)

  const route = travel.route_option === 'bridge' ? 'Bro' : 'Færge'
  return (
    <tr>
      <td>{formattedDate}</td>
      <td>{travel.pickup_time}</td>
      <td>
        {travel.pickup_location && (
          <span className="mr-1">
            <GoogleMapsPin address={travel.pickup_location} />
          </span>
        )}
        {travel.pickup_location}
      </td>
      <td>{route}</td>
      <td>{travel.ferry_time || '--'}</td>
      <td>{direction}</td>
      <td>{travel.driver.username}</td>
      <td>
        {availableSeats} af {travel.seat_capacity} ledige
      </td>
      <td>{travel.passengers.map(passenger => passenger.username).join(', ')}</td>
      <td>
        <EditTravelButton travel={travel} buttonClass="btn btn-outline-secondary btn-sm" buttonTitle="Rediger" windowTitle="Rediger" />
        {canJoin && <JoinTravelButton travel={travel} />}
        {joined && <LeaveTravelButton travel={travel} />}
      </td>
    </tr>
  )
}
