class I18nStore {
  constructor () {
    this.locales = {}
  }

  load (locales) {
    this.locales = locales
  }

  t (locale) {
    return this.locales[locale] || locale
  }

  getAllPrioritized () {
    return ['Prioritized', 'Prioriterede', 'Prioriterte', 'Prioriterade']
  }

  getAllApproved () {
    return ['Approved', 'Godkjente', 'Godkjente', 'Godkända']
  }
}

export default new I18nStore()
