import * as React from 'react'
import { ResourcesReportTimeShape } from '../../api/api_data_types'
import useResourcesReportTable from '../../hooks/tables/useResourcesReportTable'
import TableHeader from '../shared/DataGrid/TableHeader'
import Rows from '../shared/DataGrid/Rows'
import TotalRows from './TotalRows'

export default function ResourcesReportApp({ resourcesTimes }: { resourcesTimes: ResourcesReportTimeShape[] }) {
  const { table } = useResourcesReportTable(resourcesTimes)
  return (
    <div className="mb-3">
      <table className="table">
        <thead>
          <TableHeader table={table} />
        </thead>
        <tbody>
          <TotalRows table={table} />
          <Rows table={table} />
        </tbody>
      </table>
    </div>
  )
}
