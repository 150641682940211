import * as React from 'react'
import TravelListCard from './TravelListCard'
import { Travel } from './travel_list'

type TravelListCardsProps = {
  data: Travel[]
  isLoading: boolean
}

function CardDeck({ data }: { data: Travel[] }) {
  return (
    <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
      {data.map(travel => {
        return (
          <div key={travel.id} className="col mb-4">
            <TravelListCard key={travel.id} travel={travel} />
          </div>
        )
      })}
    </div>
  )
}

export default function TravelListCards({ data, isLoading }: TravelListCardsProps) {
  return (
    <div className="travel-list-card-view">
      {isLoading && (
        <div className="text-center">
          <i className="fa fa-spinner fa-spin fa-2x" />
        </div>
      )}
      {!isLoading && data && <CardDeck data={data} />}
    </div>
  )
}
