import * as React from 'react'

export function HighlightItemsCheckbox({ highlightItems, setHighlightItems }) {
  const labelClass = highlightItems ? 'text-danger' : ''
  return (
    <label>
      <input type="checkbox" checked={highlightItems} onChange={e => setHighlightItems(e.target.checked)} />
      <span className={`ml-2 ${labelClass}`}>Vis OBS varer</span>
    </label>
  )
}
