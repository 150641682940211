import * as React from 'react'
import { QueryClientProvider } from 'react-query'
import buildQueryClient from '../helpers/quieryClient'
import ItemsOverview from "./ItemsOverview";

export default function ItemsOverviewApp({ items, lastUpdated }) {
  const queryClient = buildQueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <ItemsOverview items={items} lastUpdated={lastUpdated} />
    </QueryClientProvider>
  )
}
