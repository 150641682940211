import * as React from 'react'
import { useState } from 'react'
import { format, parseISO, isSameDay } from 'date-fns'
import { da } from 'date-fns/locale'
import { resources_records_path } from '../../routes'
import DatePickerButton from '../shared/DatePickerButton'

type ResourcesRecordsDatepickerProps = {
  initialDate: string
  holiday?: string
}
export default function ResourcesRecordsDatepicker({ initialDate, holiday }: ResourcesRecordsDatepickerProps) {
  const initialSelected = parseISO(initialDate)
  const [selected, setSelected] = useState(initialSelected || new Date())
  const handleDateChange = date => {
    setSelected(date)
    if (!isSameDay(date, initialSelected)) {
      window.location.href = resources_records_path({ date: format(date, 'yyyy-MM-dd') })
    }
  }

  const formattedDate = format(selected, 'eeee, PPP', { locale: da })
  let label = <span>{formattedDate}</span>
  if (holiday) {
    label = (
      <span>
        <span>{formattedDate}</span>
        <span className="text-danger ml-2">{holiday}</span>
      </span>
    )
  }

  return <DatePickerButton buttonLabel={label} buttonClass="btn btn-outline-secondary w-100" date={selected} setDate={handleDateChange} />
}
