import * as React from 'react'
import useItemsOverviewTable from '../../hooks/tables/useItemsOverviewTable'
import DebouncedInput from '../shared/DebouncedInput'
import { formatDistance, parseISO } from 'date-fns'
import { da } from 'date-fns/locale'
import RefreshFromNavButton from './RefreshFromNavButton'
import DataGrid from '../shared/DataGrid'
import { HighlightItemsCheckbox } from './HighlightItemsCheckbox'

export default function ItemsOverview({ items, lastUpdated }) {
  const { table, globalFilter, setGlobalFilter, highlightItems, setHighlightItems } = useItemsOverviewTable(items)
  const timeDistance = lastUpdated ? formatDistance(parseISO(lastUpdated), new Date(), { locale: da }) + ' siden' : 'never'

  return (
      <div className="row">
        <div className="col-12 d-flex mb-3 justify-content-between">
          <DebouncedInput
            value={globalFilter || ''}
            onChange={value => setGlobalFilter(String(value))}
            className="form-control col-md-3"
            placeholder="Filtrer på alle kolonner..."
          />
          <div className="d-flex">
            <div className="mr-3 pt-3">
              <i className="">Sidst opdateret: {timeDistance}</i>
            </div>
            <RefreshFromNavButton />
          </div>
        </div>
        <div className="col-12 mt-2">
          <HighlightItemsCheckbox highlightItems={highlightItems} setHighlightItems={setHighlightItems} />
        </div>
        <div className="col-12 items-overview-table table-sm">
          <DataGrid table={table} />
        </div>
      </div>
  )
}
