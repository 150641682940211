import {differenceInCalendarDays, format} from 'date-fns'
import * as React from 'react'

export const DEFAULT_COLSPAN = 12

export function TableDateGroupHeader(props: { date: string }) {
  const {date} = props
  const formattedDate = format(new Date(date), 'dd-MM-yyyy')
  const daysLeft = differenceInCalendarDays(new Date(date), new Date())

  return (
    <tr className="job-planning-date-row">
      <td className="job-planning-date-col" colSpan={DEFAULT_COLSPAN}>
        {`${formattedDate} | ${daysLeft} ${Math.abs(daysLeft) > 1 ? 'dage' : 'dag'}`}
      </td>
    </tr>
  )
}