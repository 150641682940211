import * as React from 'react'
import { useState } from 'react'
import TableRow from './TableRow'
import { DEFAULT_COLSPAN } from './TableDateGroupHeader'

type TableJobRowsGroupProps = {
  jobId: string
  productionPlanningLines: any
  hideItems: boolean
  area?: string
  filter: any
  showCompletedToday: boolean
  colorClass: string
}

export function TableJobRowsGroup(props: TableJobRowsGroupProps) {
  const { productionPlanningLines, hideItems, area, showCompletedToday, jobId, colorClass, filter } = props
  const className = colorClass
  const defaultCollapseJobIds = filter?.collapse_job_ids || false

  const [hoveredJobId, setHoveredJobId] = useState(null)
  const [collapsed, setCollapsed] = useState(defaultCollapseJobIds)
  const toggleCollapse = () => {
    setCollapsed(!collapsed)
  }
  const collection = collapsed ? [productionPlanningLines[0]] : productionPlanningLines

  return (
    <>
      {collection.map(productionPlanningLine => {
        const key = `${productionPlanningLine.job_id}-${productionPlanningLine.solution_id}`
        return (
          <TableRow
            key={key}
            productionPlanningLine={productionPlanningLine}
            area={area}
            hideItems={hideItems}
            filter={filter}
            showCompletedToday={showCompletedToday}
            className={className}
            hoveredJobId={hoveredJobId}
            setHoveredJobId={setHoveredJobId}
            toggleCollapse={toggleCollapse}
          />
        )
      })}
      {collapsed && productionPlanningLines.length > 1 && (
        <tr>
          <td colSpan={DEFAULT_COLSPAN} className="text-left">
            <button className="btn btn-link production-planning-collapse-link" onClick={toggleCollapse}>
              {`Vis ${productionPlanningLines.length - 1} flere...`}
            </button>
          </td>
        </tr>
      )}
    </>
  )
}
