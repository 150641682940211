import * as React from 'react'
import Popup from 'reactjs-popup'
import { CurrentUserType } from '../../DictionariesContext'
import ScanForm from '../../scan_app/ScanForm'
import { areaToOption } from '../../scan_app/ScanFormFields'

type ScanPopupButtonProps = {
  buttonClass: string
  buttonTitle: string
  availableAreas: string[]
  currentUser: CurrentUserType
  orderNumber?: string
  orderId?: string
  initialArea?: string
  onSuccessfulScan?: () => void
}

export default function ScanPopupButton(props: ScanPopupButtonProps) {
  const { buttonClass, buttonTitle, orderNumber, orderId, initialArea, currentUser, availableAreas, onSuccessfulScan } = props

  const area = currentUser.areas.includes(initialArea) ? initialArea : currentUser.areas[0] || availableAreas[0]
  const { username, default_machine } = currentUser
  const orderAndId = orderId ? `${orderNumber}-${orderId}` : orderNumber

  const scanFormInitialValues = {
    area: areaToOption(area),
    order_and_id: orderAndId,
    operator: username,
    machine: default_machine?.no,
    order_note: '',
  }

  return (
    <Popup
      trigger={
        <button type="button" className={buttonClass || 'btn btn-outline-secondary'}>
          {buttonTitle}
        </button>
      }
      position="center center"
      contentStyle={{ width: '100%', maxWidth: '920px', maxHeight: '100%', overflowX: 'hidden', overflowY: 'auto' }}
      modal
    >
      {close => (
        <div className="resources-record-modal modal-popup">
          <a className="close" onClick={close}>
            &times;
          </a>
          <div className="popup-header">{buttonTitle}</div>
          <div className="popup-content">
            <ScanForm
              availableAreas={availableAreas}
              initialValues={scanFormInitialValues}
              scanCompletedSuccessfully={() =>
                setTimeout(() => {
                  if (onSuccessfulScan) {
                    onSuccessfulScan()
                  }
                  close()
                }, 1000)
              }
            />
          </div>
        </div>
      )}
    </Popup>
  )
}
