import * as React from 'react'
import { omit } from 'lodash'

export default function ConsumptionLineQuantityField({ row, changedQuantities, setChangedQuantities }) {
  const { quantity, key } = row

  const changed = changedQuantities[key]
  const isChanged = changed !== undefined
  const value = isChanged ? changed.quantity : quantity
  const onChange = e => {
    const val = e.target.value
    if (val === quantity) {
      const newChangedQuantities = omit(changedQuantities, key)
      setChangedQuantities(newChangedQuantities)
    } else {
      setChangedQuantities({
        ...changedQuantities,
        [key]: { quantity: val, job_id: row.job_id, item_id: row.item.id, line_no: row.line_no },
      })
    }
  }
  const className = `form-control consumption-line-quantity-field${isChanged ? ' is-changed' : ''}`

  return <input className={className} type="number" step={0.1} value={value || ''} onChange={onChange} />
}
