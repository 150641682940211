import * as React from 'react'
import TermsStore from '../../stores/TermsStore'
import I18nStore from '../../stores/I18nStore'
import VendorListStore from '../../stores/VendorListStore'
import Select from 'react-select'

export default function CategoryList() {
  return (
    <div className="category-list-container">
      <Select
        name="form-field-name"
        value={VendorListStore.getFilterCategories()}
        isMulti
        isClearable
        closeMenuOnSelect
        placeholder={I18nStore.t('categories_placeholder')}
        options={TermsStore.categories}
        onChange={categories => VendorListStore.setCategories(categories)}
      />
    </div>
  )
}
