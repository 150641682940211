import _ from 'lodash'
import {EventEmitter} from 'fbemitter'
import MarkerClusterer from 'node-js-marker-clusterer'

const google = window.google
const STATIC_PATH = '/google_map'
const MIN_CLUSTER_SIZE = 999
const MAX_ZOOM = 15

class MapStore extends EventEmitter {
  constructor () {
    super()
    this.map = null
    this.clusterCommon = null
    this.markers = []
  }

  createMap (container) {
    this.map = new google.maps.Map(container, { mapTypeControl: false, streetViewControl: false })
  }

  createCluster () {
    this.clusterCommon = new MarkerClusterer(this.map, [], {
      imagePath: `${STATIC_PATH}/m`,
      minimumClusterSize: MIN_CLUSTER_SIZE
    })
  }

  addMarker (marker) {
    this.markers.push(marker)
  }

  setCenter (coordinates) {
    this.map.setCenter(coordinates)
  }

  setZoom (zoom) {
    this.map.setZoom(zoom)
  }

  createMarker (vendor) {
    return new google.maps.Marker({
      position: {lat: vendor.latitude, lng: vendor.longitude},
      icon: `${STATIC_PATH}/vendor_off.png`,
      title: vendor.name
    })
  }

  syncMarkersWithCluster () {
    this.clusterCommon.addMarkers(this.markers)
  }

  clearMarkers () {
    this.markers = []
    this.clusterCommon.clearMarkers()
  }

  fitMapToMarkers () {
    let bounds = new google.maps.LatLngBounds()
    _.each(this.markers, (marker) => {
      if (marker.getPosition().lat() && marker.getPosition().lng()) {
        bounds.extend(marker.getPosition())
      }
    })
    if (!bounds.isEmpty()) { this.map.fitBounds(bounds) }
    if (this.map.getZoom() > MAX_ZOOM) { this.map.setZoom(MAX_ZOOM) }
  }

  resetMarkerIcons () {
    _.each(this.markers, (marker) => {
      marker.setIcon(`${STATIC_PATH}/vendor_off.png`)
      marker.setZIndex(1)
    })
  }
}

export default new MapStore()
export { STATIC_PATH }
