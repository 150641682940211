import * as React from 'react'
import { JobPlanningLineShape } from '../../api/api_data_types'
import { useContext } from 'react'
import DictionariesContext from '../DictionariesContext'

type BrandEasyProjectButtonProps = {
  jobPlanning: JobPlanningLineShape
}
export default function BrandEasyProjectButton(props: BrandEasyProjectButtonProps) {
  if (!props.jobPlanning.brandeasy_project_id) return null

  const { jobPlanning } = props
  const { smProjectUrl } = useContext(DictionariesContext)
  const href = smProjectUrl + encodeURIComponent(jobPlanning.brandeasy_project_id)
  return (
    <a className="btn btn-outline-secondary text-nowrap" href={href} target="_blank">
      BrandEasy Project
    </a>
  )
}
