import React from 'react'
import ModalsStore from '../../../stores/ModalsStore'
import I18nStore from '../../../stores/I18nStore'

class VendorInfoModal extends React.Component {
  render () {
    return (
      <div className="vendor-info-modal-container">
        <div className="vendor-info-modal-title"><strong>{ModalsStore.data.model.name}</strong></div>
        {this.renderDescription()}
        <div>{ModalsStore.data.model.street}</div>
        <div className="vendor-info-modal-zipAndCity">{ModalsStore.data.model.zip} {ModalsStore.data.model.city}</div>
        {ModalsStore.data.model.phone && <div>{ModalsStore.data.model.phone}</div>}
        {ModalsStore.data.model.contactPerson && <div>{ModalsStore.data.model.contactPerson}</div>}
        {this.renderEmail()}
        {this.renderWebsite()}
        {this.renderHourlyRate()}
        <div className="vendor-info-modal-categories"><strong>{I18nStore.t('categories')}</strong></div>
        <div>{ModalsStore.data.model.getCategoryNames()}</div>
        <div className="vendor-info-modal-capabilities"><strong>{I18nStore.t('capabilities')}</strong></div>
        <div>{ModalsStore.data.model.getTagNames()}</div>
      </div>
    )
  }

  renderDescription () {
    let description = ModalsStore.data.model.description
    if (description) {
      return (
        <div className="vendor-info-modal-description" dangerouslySetInnerHTML={{__html: description}} />
      )
    }
  }

  renderEmail () {
    let email = ModalsStore.data.model.email
    if (email) {
      return (
        <div>
          <a target="_blank" href={`mailto:${email}?Subject=Hello`}>
            {email}
          </a>
        </div>
      )
    }
  }

  renderWebsite () {
    let website = ModalsStore.data.model.website
    if (website) {
      return (
        <div>
          <a target="_blank" href={`//${website}`}>
            {website}
          </a>
        </div>
      )
    }
  }

  renderHourlyRate () {
    let hourlyRate = ModalsStore.data.model.hourlyRate
    if (hourlyRate) {
      return (
        <div>{hourlyRate}</div>
      )
    }
  }
}

export default VendorInfoModal
