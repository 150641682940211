import * as React from 'react'
import ConsumptionLineQuantityField from './ConsumptionLineQuantityInput'
import ConsumptionLineCopyQuantityButton from "./ConsumptionLineCopyQuantityButton";

export default function ConsumptionLineRow({ row, changedQuantities, setChangedQuantities }) {
  const estimatedQuantity = parseFloat(row.estimated_quantity)
  const jobCostQuantity = parseFloat(row.job_cost_quantity)
  return (
    <tr>
      <th className="align-middle">{row.user_no}</th>
      <th className="align-middle text-nowrap">{row.date}</th>
      <th>{row.item && row.item.title}</th>
      <th className="align-middle">{row.location_code}</th>
      <th className="align-middle">
        <ConsumptionLineQuantityField
          row={row}
          changedQuantities={changedQuantities}
          setChangedQuantities={setChangedQuantities}
        />
      </th>
      <th className="align-middle">
        <ConsumptionLineCopyQuantityButton
          row={row}
          changedQuantities={changedQuantities}
          setChangedQuantities={setChangedQuantities}
        />
      </th>
      <th className="align-middle">{estimatedQuantity.toLocaleString('da-DK')}</th>
      <th className="align-middle">{jobCostQuantity.toLocaleString('da-DK')}</th>
    </tr>
  )
}
