import * as React from 'react'
import { Travel } from './travel_list'
import { useDestroyTravelMutation } from '../../hooks/queries/travels'

export default function DeleteTravelButton({ travel }: { travel: Travel }) {
  const mutation = useDestroyTravelMutation()
  const onClick = () => {
    if (confirm('Are you sure to delete the travel?')) {
      mutation.mutate(travel.id)
    }
  }
  return (
    <button type="button" className="btn btn-outline-danger" onClick={onClick}>
      { mutation.isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> }
      { !mutation.isLoading && 'Delete' }
    </button>
  )
}
