import * as React from 'react'

import useCompleteJobPlanningTable from '../../../hooks/tables/useCompleteJobPlanningTable'
import Rows from '../../shared/DataGrid/Rows'
import TableHeader from '../../shared/DataGrid/TableHeader'

type TableViewProps = {
  data: any
  reload: () => void
  setApiError: (error: any) => void
}

export default function TableView(props: TableViewProps) {
  const { data, reload, setApiError } = props
  const { table } = useCompleteJobPlanningTable(data || [], reload, setApiError)
  return (
    <table className="table">
      <thead>
        <TableHeader table={table} />
      </thead>
      <tbody>{data && <Rows table={table} />}</tbody>
    </table>
  )
}