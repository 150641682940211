import * as React from 'react'
import { useSyncJobNumberMutation } from '../../hooks/queries/production_planning'

type SyncJobNumberButtonProps = {
  jobId: string
  filter: any
  showCompletedToday: boolean
}
export default function SyncJobNumberButton(props: SyncJobNumberButtonProps) {
  const { jobId, showCompletedToday, filter } = props
  const mutation = useSyncJobNumberMutation(filter, showCompletedToday)
  return (
    <button
      className="btn btn-outline-secondary"
      title="Sync job number"
      onClick={() => {
        mutation.mutate({ job_id: jobId })
      }}
    >
      {mutation.isLoading && <i className={'fa fa-spinner fa-spin'} />}
      {!mutation.isLoading && <i className={'fa fa-refresh'} />}
    </button>
  )
}
