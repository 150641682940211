import _ from 'lodash'

const Image = function (attrs = {}) {
  this.width = attrs.width
  this.height = attrs.height
  this.url = attrs.url
}

_.extend(Image.prototype, {
})

export default Image
