import _ from 'lodash'
import {EventEmitter} from 'fbemitter'
import Vendor from '../models/Vendor'
import Filter from '../models/Filter'
import TermsStore from '../stores/TermsStore'

class VendorListStore extends EventEmitter {
  constructor () {
    super()
    this.list = []
    this.filteredList = []
    this.additionalFilteredLists = {
      exceptCategories: [],
      exceptPriorities: []
    }
    this.filter = new Filter()
  }

  load (vendors, terms) {
    _.each(vendors, (vendor) => {
      let categories = _.map(vendor.categories, (c) => terms.categoriesMap[parseInt(c)])
      console.log(vendor.priority, terms.prioritiesMap)
      let priority = terms.prioritiesMap[parseInt(vendor.priority)]
      let tags = _.map(vendor.tags, (t) => terms.tagsMap[parseInt(t)])
      this.list.push(new Vendor(vendor, categories, priority, tags))
    })
    this.update()
  }

  performFilter () {
    this.filteredList = _.filter(this.list, (vendor) => {
      return this.filter.byAll(vendor)
    })
    // TODO: experimental filtering for specific amount of categories and priorities
    this.additionalFilteredLists.exceptCategories = _.filter(this.list, (vendor) => {
      return this.filter.byAllExceptCategories(vendor)
    })
    this.additionalFilteredLists.exceptPriorities = _.filter(this.list, (vendor) => {
      return this.filter.byAllExceptPriorities(vendor)
    })
    this.filteredList = _.orderBy(this.filteredList, [
      (vendor) => { return !vendor.isPriority() },
      (vendor) => { return !vendor.isApproved() },
      (vendor) => { return vendor.name.toLowerCase() }
    ])
  }

  getFilterCategories () {
    return this.filter.categories
  }

  setCategories (categories) {
    this.filter.categories = categories
    this.update()
  }

  setSearchText (text) {
    this.filter.searchText = text
    this.update()
  }

  /**
   * Use for priorities and tags
   *
   * @param priority
   * @param type [priorityIds, tagIds]
   * @param value [true, false]
   */
  toggleFilterItem (priority, type, value) {
    if (value) {
      this.filter[type].push(priority.id)
    } else {
      _.pull(this.filter[type], priority.id)
    }
    this.update()
  }

  togglePriority (priority) {
    this.filter.priorityIds = [priority.id]
    this.update()
  }

  update () {
    this.performFilter()
    TermsStore.refreshAmount(this.filteredList, this.additionalFilteredLists)
    this.emit('change')
  }

  highlight (vendor, state) {
    vendor.highlight = state
    this.update()
  }

  clearFilter (type) {
    if (type === 'tags') { this.filter.tagIds = [] }
    if (type === 'priorities') { this.filter.priorityIds = [] }
    this.update()
  }

  setFilterByDefaultData () {
    let defaultPriority = this.filter.getDefaultPriority(TermsStore.prioritiesMap)
    this.togglePriority(defaultPriority)
  }
}

export default new VendorListStore()
