import _ from 'lodash'

const Category = function (attrs = {}) {
  this.id = attrs.id
  this.name = attrs.name
  this.amount = 0

  // need for select
  this.value = attrs.id
  this.updateLabel()
}

_.extend(Category.prototype, {
  updateLabel () {
    this.label = `${this.name} (${this.amount})`
  }
})

export default Category
