import * as React from 'react'
import { flexRender } from '@tanstack/react-table'
import Filter from '../../items_overview_app/ColumnFilter'

function SortIndicator({ isSorted }) {
  if (!isSorted) {
    return <i className={'ml-1 fas fa-sort text-secondary'} />
  }
  return {
    asc: <i className={'ml-1 fas fa-sort-up'} />,
    desc: <i className={'ml-1 fas fa-sort-down'} />,
  }[isSorted]
}

function TableHeaderCell({ header, table }) {
  return (
    <th colSpan={header.colSpan} className="align-top">
      {header.isPlaceholder ? null : (
        <>
          <div
            className={header.column.getCanSort() ? 'cursor-pointer select-none text-nowrap' : ''}
            onClick={header.column.getToggleSortingHandler()}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
            {header.column.getCanSort() && <SortIndicator isSorted={header.column.getIsSorted()} />}
          </div>
          {header.column.getCanFilter() ? (
            <div>
              <Filter column={header.column} table={table} />
            </div>
          ) : null}
        </>
      )}
    </th>
  )
}

export default function TableHeader({ table }) {
  return table.getHeaderGroups().map(headerGroup => (
    <tr key={headerGroup.id}>
      {headerGroup.headers.map(header => {
        return <TableHeaderCell key={header.id} header={header} table={table} />
      })}
    </tr>
  ))
}
