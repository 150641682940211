import _ from 'lodash'
import React from 'react'
import VendorListStore from '../../stores/VendorListStore'

class Priority extends React.Component {
  // propTypes: {
  //   model: React.PropTypes.object.isRequired
  // },

  toggle (e) {
    VendorListStore.toggleFilterItem(this.props.model, 'priorityIds', e.currentTarget.checked)
  }

  render () {
    return (
      <div className="vendor-directory-priority-container">
        <label>
          <input type="checkbox" onChange={(e) => this.toggle(e)} checked={this.getState()} disabled={this.props.model.isPrioritized()} />
          {this.props.model.name} ({this.props.model.amount})
        </label>
      </div>
    )
  }

  getState () {
    return _.includes(VendorListStore.filter.priorityIds, this.props.model.id)
  }
}

export default Priority
