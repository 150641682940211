import * as React from 'react'
import { Table } from '@tanstack/react-table'
import { ResourcesReportTimeShape } from '../../api/api_data_types'
import { minutesToTime } from '../../components/helpers/time'

interface GroupedTimeShape {
  time: number
  worktype: ResourcesReportTimeShape['worktype']
}

export default function TotalRows({ table }: { table: Table<ResourcesReportTimeShape> }) {
  const { rows } = table.getRowModel()
  const reportTimes = rows.map(row => row.original)

  let groupedTimes: GroupedTimeShape[] = []
  reportTimes.forEach(reportTime => {
    const worktype = reportTime.worktype
    if (!worktype) return

    const time = reportTime.time
    const id = worktype.id
    if (groupedTimes[id]) {
      groupedTimes[id]['time'] += time
    } else {
      groupedTimes[id] = { time, worktype }
    }
  })
  const totalTime = groupedTimes.reduce((acc, time) => acc + time.time, 0)

  return (
    <>
      <tr className="table-secondary">
        <td className="text-align-left" colSpan={4}>
          <strong>TOTAL:</strong>
        </td>
        <td>
          <strong>{minutesToTime(totalTime)}</strong>
        </td>
      </tr>
      {groupedTimes.map((time, id) => (
        <tr className="weekly-reports-info-table-total-row" key={id}>
          <td />
          <td>{time.worktype.category}</td>
          <td colSpan={2}>{time.worktype.code}</td>
          <td>{minutesToTime(time.time)}</td>
        </tr>
      ))}
    </>
  )
}
