import _ from 'lodash'
import { STATIC_PATH } from '../stores/MapStore'
import I18nStore from '../stores/I18nStore'

const Vendor = function (attrs = {}, categories, priority, tags) {
  this.id = attrs.id
  this.name = attrs.name
  this.description = attrs.description
  this.street = attrs.street
  this.zip = attrs.zip
  this.city = attrs.city
  this.email = attrs.email
  this.website = attrs.website
  this.phone = attrs.phone
  this.hourlyRate = attrs.hourly_rate
  this.contactPerson = attrs.contact_person
  this.latitude = parseFloat(attrs.latitude)
  this.longitude = parseFloat(attrs.longitude)
  this.categories = categories
  this.priority = priority
  this.tags = tags

  this.highlight = false
}

_.extend(Vendor.prototype, {
  getCategoryNames () {
    return _.map(this.categories, t => t.name).join(', ')
  },

  getTagNames () {
    return _.map(this.tags, t => t.name).join(', ')
  },

  getTagIds () {
    return _.map(this.tags, t => t.id)
  },

  isPriority () {
    return _.includes(I18nStore.getAllPrioritized(), this.priority.name)
  },

  isApproved () {
    return _.includes(I18nStore.getAllApproved(), this.priority.name)
  },

  setMarkerIcon (name) {
    this.marker.setIcon(`${STATIC_PATH}/${name}.png`)
    this.marker.setZIndex(name === 'vendor_on' ? 10 : 1)
  },

  getCoordinates () {
    return {lat: this.latitude, lng: this.longitude}
  },

  hasCoordinates () {
    return this.latitude && this.longitude
  }
})

export default Vendor
