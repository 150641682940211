import React from 'react'
import Modals from '../../components/Modals'
import ModalsStore from '../../stores/ModalsStore'

class ModalWrapper extends React.Component {
  componentDidMount () {
    this._storeListener = ModalsStore.addListener('change', () => this.forceUpdate())
    document.addEventListener('keydown', this.handleEscKey, false)
  }

  componentWillUnmount () {
    this._storeListener.remove()
    document.removeEventListener('keydown', this.handleEscKey, false)
  }

  handleEscKey (event) {
    if (ModalsStore.currentModal && event.keyCode === 27) {
      ModalsStore.closeModal()
    }
  }

  onClickContainer () {
    ModalsStore.closeModal()
  }

  onClickContent (e) {
    e.stopPropagation()
  }

  render () {
    let Modal = Modals[ModalsStore.currentModal]
    if (Modal) {
      return (
        <div className="modal-wrapper-modalContainer" onClick={this.onClickContainer}>
          <div className="modal-wrapper-modalContent" onClick={this.onClickContent}>
            <Modal />
            <i className="modal-wrapper-modalClose fa fa-times"
               onClick={ModalsStore.closeModal.bind(ModalsStore)} />
          </div>
        </div>
      )
    } else {
      return <div />
    }
  }
}

export default ModalWrapper
