import _ from 'lodash'
import {EventEmitter} from 'fbemitter'
import Category from '../models/Category'
import Priority from '../models/Priority'
import Tag from '../models/Tag'
class TermsStore extends EventEmitter {
  constructor () {
    super()
    this.categories = []
    this.tags = []
    this.priorities = []
    this.categoriesMap = {}
    this.prioritiesMap = {}
    this.tagsMap = {}
  }

  load (terms) {
    _.each(terms, (term) => {
      if (term.taxonomy === 'vendor_category') {
        let category = new Category(term)
        this.categoriesMap[term.id] = category
        this.categories.push(category)
      }
      if (term.taxonomy === 'vendor_priority') {
        let priority = new Priority(term)
        this.prioritiesMap[term.id] = priority
        this.priorities.push(priority)
      }
      if (term.taxonomy === 'vendor_tag') {
        let tag = new Tag(term)
        this.tagsMap[term.id] = tag
        this.tags.push(tag)
      }
    })
    this.priorities = _.orderBy(this.priorities, [
      (priority) => { return !priority.isPrioritized() }
    ])
    this.tags = _.orderBy(this.tags, [
      (tag) => { return tag.name.toLowerCase() }
    ])
  }

  getTerms () {
    return {
      categoriesMap: this.categoriesMap,
      prioritiesMap: this.prioritiesMap,
      tagsMap: this.tagsMap
    }
  }

  flushAmount () {
    _.each(this.prioritiesMap, (p) => { p.amount = 0 })
    _.each(this.tagsMap, (p) => { p.amount = 0 })
    _.each(this.categoriesMap, (p) => { p.amount = 0 })
  }

  refreshAmount (filteredVendors, additionalFilteredLists) {
    this.flushAmount()
    _.each(filteredVendors, (vendor) => {
      _.each(vendor.tags, (vendorTag) => {
        let tag = this.tagsMap[vendorTag.id]
        if (tag) { tag.amount++ }
      })
    })
    _.each(additionalFilteredLists.exceptCategories, (vendor) => {
      _.each(vendor.categories, (vendorCategory) => {
        let category = this.categoriesMap[vendorCategory.id]
        if (category) { category.amount++ }
      })
    })
    _.each(this.categoriesMap, (c) => { c.updateLabel() })
    _.each(additionalFilteredLists.exceptPriorities, (vendor) => {
      let priority = this.prioritiesMap[vendor.priority.id]
      if (priority) { priority.amount++ }
    })
  }
}

export default new TermsStore()
