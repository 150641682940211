import _ from 'lodash'
import React from 'react'
import TermsStore from '../../stores/TermsStore'
import Tag from '../../components/Tag'
import I18nStore from '../../stores/I18nStore'
import VendorListStore from '../../stores/VendorListStore'

const TAG_LIMIT = 20

class TagList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: 'collapsed',
    }
  }

  render() {
    return (
      <div className="vendors-directory-tag-list-container">
        <div className="vendors-directory-tag-list-firstContainer">
          {this.renderTimes()}
          <strong>{I18nStore.t('tags')}</strong>
        </div>
        {this.renderTags()}
        {this.renderToggleBar()}
      </div>
    )
  }

  clearFilter() {
    VendorListStore.clearFilter('tags')
  }

  isExpanded() {
    return this.state.type === 'expanded'
  }

  toggleTags() {
    this.setState({ type: this.isExpanded() ? 'collapsed' : 'expanded' })
  }

  renderTags() {
    let visibleTags = this.getVisibleTags()
    let tags = this.isExpanded() ? visibleTags : _.take(visibleTags, TAG_LIMIT)
    return (
      <div>
        {_.map(tags, tag => {
          return <Tag key={tag.id} model={tag} />
        })}
      </div>
    )
  }

  renderTimes() {
    if (VendorListStore.filter.tagIds.length) {
      return <i onClick={this.clearFilter} className="fa fa-times vendors-directory-tag-list-clearAll" />
    }
  }

  getVisibleTags() {
    return _.filter(TermsStore.tags, t => t.amount || _.includes(VendorListStore.filter.tagIds, t.id))
  }

  renderToggleBar() {
    let visibleTags = this.getVisibleTags()
    if (visibleTags.length <= TAG_LIMIT) {
      return
    }
    if (this.isExpanded()) {
      return (
        <div className="vendors-directory-tag-list-toggleBar" onClick={() => this.toggleTags()}>
          {I18nStore.t('hide_tags')}
        </div>
      )
    } else {
      return (
        <div className="vendors-directory-tag-list-toggleBar" onClick={() => this.toggleTags()}>
          {I18nStore.t('show_more_tags').replace(/#{amount}/i, visibleTags.length - TAG_LIMIT)}
        </div>
      )
    }
  }
}

export default TagList
