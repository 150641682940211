import * as React from 'react'
import { useRefreshJobCostingLinesMutation } from '../../../hooks/queries/job_costing_lines'
import { JobNumberShape } from '../../../api/api_data_types'
import ErrorMessage from '../list_app/ErrorMessage'

interface RefreshButtonProps {
  jobnumber: JobNumberShape
  className?: string
}

export default function RefreshButton({ jobnumber, className = '' }: RefreshButtonProps) {
  const mutation = useRefreshJobCostingLinesMutation(jobnumber.job_id)

  return (
    <span className={className}>
      <ErrorMessage className="d-inline error-message" error={mutation.error} isError={mutation.isError} />

      {!mutation.isError && (
        <button className="btn btn-sm btn-outline-primary" onClick={() => mutation.mutate()}>
          {mutation.isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
          {!mutation.isLoading && "Indlæs opgave"}
        </button>
      )}
    </span>
  )
}
