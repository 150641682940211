import {useMutation, useQuery, useQueryClient} from 'react-query'
import { getProductionPlanningOverview, syncJobNumber } from '../../api/production_planning'
import { useState } from 'react'

export function useProductionPlanningOverviewQuery(filter, showCompletedToday) {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(3000)
  const key = ['production_planning_overview', filter, showCompletedToday]
  const fetcher = () => getProductionPlanningOverview({ filter, show_completed_today: showCompletedToday })

  const query = useQuery(key, fetcher, {
    refetchInterval,
    onSuccess: data => {
      if (data.status !== 'in_progress') {
        setRefetchInterval(false)
      }
    },
    onError: () => {
      setRefetchInterval(false)
    },
  })

  const data = query.data?.status === 'in_progress' ? null : query.data
  const isFetching = query.isFetching || !data

  return { ...query, data, isFetching }
}

export function useSyncJobNumberMutation(filter, showCompletedToday) {

  const queryClient = useQueryClient()
  const updateProductionPlannings = (data ) => {
    const jobId = data.job_number.job_id
    const orderNumber = data.job_number.order_number

    if (orderNumber) {
      queryClient.setQueryData(['production_planning_overview', filter, showCompletedToday], lines => {
        // update corresponding lines with the new order number
        return Object.fromEntries(Object.entries(lines).map(([date, lines]) => [
          date,
          lines.map(line => {
            if (line.job_id.toString() == jobId) {
              return { ...line, order_number: orderNumber }
            }
            return line
          }),
        ]))

      })

    }
  }
  return useMutation(syncJobNumber, { onSuccess: updateProductionPlannings })
}
