import * as React from 'react'
import ItemPicker from '../../resources_records/ItemPicker'
import { loadJobCostingItemOptions } from '../../../api/items'
import DictionariesContext from '../../DictionariesContext'
import { useContext } from 'react'

export default function ConsumptionLineFormFields({ jobCostingLine, values, setValues, isItemPickerDisabled }) {
  const inputName = field => `job_costing_line[${field}]`
  const inputId = field => `job-costing-line-form-${field}`
  const { availableWarehouses } = useContext(DictionariesContext)

  return (
    <>
      <div className="row">
        <div className="col-sm-12 form-group">
          <label htmlFor={inputId('item')}>Vare</label>
          <ItemPicker
            item={values.item || {}}
            setItem={val => setValues({ ...values, item: val || {} })}
            id={inputId('item')}
            name={inputName('item_id')}
            isDisabled={isItemPickerDisabled}
            customLoadOptions={loadJobCostingItemOptions}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 form-group">
          <label htmlFor={inputId('user')}>Bruger</label>
          <input
            id={inputId('user')}
            type="text"
            className="form-control col-xs-12"
            name={inputName('user')}
            defaultValue={jobCostingLine.user_no}
            disabled
          />
        </div>
        <div className="col-sm-12 form-group">
          <label htmlFor={inputId('date')}>Dato</label>
          <input
            id={inputId('date')}
            required
            type="date"
            className="form-control col-xs-12"
            name={inputName('date')}
            value={values.date}
            onChange={e => setValues({ ...values, date: e.target.value })}
          />
        </div>
        <div className="col-sm-12 form-group">
          <label htmlFor={inputId('location_code')}>Lokation</label>
          <select
            id={inputId('location_code')}
            required
            className="form-control col-xs-12"
            name={inputName('location_code')}
            value={values.location_code}
            onChange={e => setValues({ ...values, location_code: e.target.value })}
          >
            <option value="">Vælg lokation</option>
            {availableWarehouses.map(warehouse => (
              <option key={warehouse[1]} value={warehouse[1]}>
                {warehouse[0]}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 form-group">
          <label htmlFor={inputId('quantity')}>Antal</label>
          <input
            id={inputId('quantity')}
            required
            type="number"
            step={0.1}
            className="form-control col-xs-12"
            name={inputName('quantity')}
            value={values.quantity}
            onChange={e => setValues({ ...values, quantity: e.target.value })}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 form-group">
          <label htmlFor={inputId('text')}>Beskrivelse</label>
          <textarea
            id={inputId('text')}
            required
            className="form-control col-xs-12"
            name={inputName('text')}
            value={values.text}
            onChange={e => setValues({ ...values, text: e.target.value })}
          />
        </div>
      </div>
    </>
  )
}
