import {EventEmitter} from 'fbemitter'

class ModalsStore extends EventEmitter {
  constructor () {
    super()
    this.currentModal = null
    this.data = {}
  }
  openModal (modalName, data) {
    this.currentModal = modalName
    this.data = data
    this.update()
  }

  closeModal () {
    this.currentModal = null
    this.update()
  }

  update () {
    this.emit('change')
  }
}

export default new ModalsStore()
