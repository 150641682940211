import * as React from 'react'
import { Table } from '@tanstack/react-table'
import TableHeader from '././DataGrid/TableHeader'
import Paginator from '././DataGrid/Paginator'
import Rows from './DataGrid/Rows'

export type DataGridOptions = {
  pagination?: boolean
}
export default function DataGrid({ table, options = { pagination: true } }: { table: Table<any>; options?: DataGridOptions }) {
  const { pagination = true } = options

  return (
    <>
      <table className="table">
        <thead>
          <TableHeader table={table} />
        </thead>
        <tbody>
          <Rows table={table} />
        </tbody>
      </table>
      {pagination !== false && table.getRowModel().rows.length > 0 && (
        <div className="mb-5 mt-4">
          <Paginator table={table} />
        </div>
      )}
    </>
  )
}
