import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import DictionariesContext from '../../DictionariesContext'
import useCompleteJobPlanningController from '../../../hooks/useCompleteJobPlanningController'

function CompleteButton(props) {
  const { jobPlanning, reload, setApiError } = props
  const { currentUser, availableMachines } = useContext(DictionariesContext)

  const { performRequest, isLoading, isAllowed, isCompleted } = useCompleteJobPlanningController(
    jobPlanning,
    currentUser,
    availableMachines,
    reload,
    setApiError
  )


  const cssClasses = 'btn btn-outline-primary complete-button'
  if (isLoading) {
    return (
      <button type="button" className={cssClasses} disabled>
        <i className="fa fa-spinner fa-spin" />
      </button>
    )
  }

  if (isAllowed) {
    const buttonTitle = isCompleted ? 'Fortryd' : 'Færdig'
    return (
      <button type="button" className={cssClasses} onClick={performRequest}>
        {buttonTitle}
      </button>
    )
  } else {
    return isCompleted ? 'Afsluttet' : 'Ikke afsluttet'
  }
}

CompleteButton.propTypes = {
  reload: PropTypes.func.isRequired,
  jobPlanning: PropTypes.object.isRequired,
}

export default CompleteButton
