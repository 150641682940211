import * as React from 'react'
import { JobPlanningLineShape } from '../../../api/api_data_types'
import Select from 'react-select'
import ErrorMessage from '../list_app/ErrorMessage'
import RecipientCheckbox from './RecipientCheckbox'
import DirectUploadInput from './DirectUploadInput'
import { useState } from 'react'

interface SendMessageFormProps {
  jobPlanning: JobPlanningLineShape
  controller: any
  closePopup: () => void
}

export default function SendMessageForm(props: SendMessageFormProps) {
  const { jobPlanning, controller, closePopup } = props
  const { formFields, setFormFields, sendMessageMutation, users } = controller
  const { isError, error, isSuccess, mutate } = sendMessageMutation
  const [allFilesUploaded, setAllFilesUploaded] = useState(true)

  return (
    <form action="#" method="POST" encType="multipart/form-data">
      <div className="resources-record-modal-content popup-content">
        <ErrorMessage className="mb-2 error-message" error={error} isError={isError} />
        {isSuccess && <div className="alert alert-success">Din besked er sendt</div>}
        {!isSuccess && (
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <div className="message-popup-recipients">
                  <label className="message-popup-recipient">Til:</label>
                  <RecipientCheckbox
                    value={jobPlanning.project_creator}
                    title="Ordrebehandler"
                    formFields={formFields}
                    setFormFields={setFormFields}
                  />
                  <RecipientCheckbox
                    value={jobPlanning.project_manager}
                    title="Projektleder"
                    formFields={formFields}
                    setFormFields={setFormFields}
                  />
                  <RecipientCheckbox value={jobPlanning.salesperson} title="Sælger" formFields={formFields} setFormFields={setFormFields} />

                  <div className="w-100">
                    <Select
                      id="message-popup-recipient-user"
                      isMulti
                      placeholder="Vælg eller skriv..."
                      value={formFields.recipients.map(username => users.find(u => u.value === username))}
                      onChange={opts => {
                        const usernames = opts === null ? [] : opts.map(o => o.value)
                        return setFormFields({ ...formFields, recipients: usernames })
                      }}
                      options={users}
                    />
                    {formFields.recipients.map(username => {
                      return <input type="hidden" name="message[recipients][]" value={username} key={username} />
                    })}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="pl-0 form-check form-check-valigned">
                  <input
                    type="checkbox"
                    name="message[production_material_approved]"
                    checked={formFields.production_material_approved}
                    onChange={e => setFormFields({ ...formFields, production_material_approved: e.target.checked })}
                  />
                  Produktionsmateriale godkendt
                </label>
              </div>
              <div className="form-group">
                <label>Besked</label>
                <textarea
                  className="form-control message-popup-body"
                  name="message[body]"
                  value={formFields.body}
                  onChange={e =>
                    setFormFields({
                      ...formFields,
                      body: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label className="d-block">Vedhæft filer (billeder, dokumenter etc.)</label>
                <DirectUploadInput
                  // name="message[attachments]"
                  setAllFilesUploaded={setAllFilesUploaded}
                  onChange={files => {
                    setFormFields({ ...formFields, attachments: files.map(blob => blob.signed_id) })
                  }}
                />
                {!allFilesUploaded && (
                  <div className="mt-2 text-right">
                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
                    Vent venligst, mens filer uploades...
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="resources-record-modal-actions popup-actions">
        <button type="button" className="btn btn-outline-secondary" onClick={closePopup}>
          Luk
        </button>
        {!isSuccess && !isError && (
          <button
            type="submit"
            className="btn btn-primary"
            disabled={formFields.recipients.length === 0 || !allFilesUploaded}
            onClick={e => {
              e.preventDefault()
              mutate()
            }}
          >
            {allFilesUploaded ? 'Send' : <span className="spinner-border spinner-border-sm ml-2 mr-2" role="status" aria-hidden="true" />}
          </button>
        )}
      </div>
    </form>
  )
}
