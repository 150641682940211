import _ from 'lodash'
import I18nStore from '../stores/I18nStore'

const Priority = function (attrs = {}) {
  this.id = attrs.id
  this.name = attrs.name
  this.amount = 0
}

_.extend(Priority.prototype, {
  isPrioritized () {
    return _.includes(I18nStore.getAllPrioritized(), this.name)
  }
})

export default Priority
