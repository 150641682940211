import _ from 'lodash'
import React from 'react'
import VendorListStore from '../../stores/VendorListStore'
import I18nStore from '../../stores/I18nStore'
import Vendor from '../../components/Vendor'

const VENDOR_LIMIT = 6
class VendorList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      type: 'collapsed',
    }
  }

  render () {
    if (VendorListStore.filteredList.length) {
      return (
        <div className="vendor-list-container">
          {this.renderVendors()}
          {this.renderToggleBar()}
        </div>
      )
    } else {
      return (
        <div className="vendor-list-container">
          <div style={{textAlign: 'center'}}>
            {I18nStore.t('no_vendors')}
          </div>
        </div>
      )
    }
  }

  isExpanded () {
    return this.state.type === 'expanded'
  }

  toggleVendors () {
    this.setState({type: this.isExpanded() ? 'collapsed' : 'expanded'})
  }

  renderVendors () {
    let vendors = this.isExpanded() ? VendorListStore.filteredList : _.take(VendorListStore.filteredList, VENDOR_LIMIT)
    return (
      <div>
        {_.map(vendors, (vendor) => {
          return <Vendor key={vendor.id} model={vendor} />
        })}
      </div>
    )
  }

  renderToggleBar () {
    if (VendorListStore.filteredList.length <= VENDOR_LIMIT) { return }
    if (this.isExpanded()) {
      return (
        <div className="vendor-list-toggleBar" onClick={() => this.toggleVendors()}>{I18nStore.t('hide_vendors')}</div>
      )
    } else {
      return (
        <div className="vendor-list-toggleBar" onClick={() => this.toggleVendors()}>
          {I18nStore.t('show_more_vendors').replace(/#{amount}/i, VendorListStore.filteredList.length - VENDOR_LIMIT)}
        </div>
      )
    }
  }
}

export default VendorList
