import * as React from 'react'
import MinutesPicker from '../resources_records/MinutesPicker'
import HoursPicker from '../resources_records/HoursPicker'
import { separateTime } from '../shared/utils'

type FormFieldsProps = {
  availableJobnumbers: {
    id: number
    jobnumber: string
    title: string
    job_id: string
  }[]
  availableDepartments: {
    id: number
    title: string
  }[]
  formData: any
  setFormData: (formData: any) => void
}

function frequencyMultiplier(frequency: string) {
  switch (frequency) {
    case 'daily':
      return 220
    case 'weekly':
      return 44
    case 'monthly':
      return 12
    default:
      return 1
  }
}

export default function FormFields(props: FormFieldsProps) {
  const { setFormData, formData, availableJobnumbers, availableDepartments } = props

  const [hours, minutes] = separateTime(Math.abs(formData.minutes))
  const effect = (hours + (minutes / 60)) * frequencyMultiplier(formData.frequency)
  const formattedEffect = effect.toLocaleString('da-DK', { maximumFractionDigits: 2 })
  const effectCalculation = `${hours} timer * ${frequencyMultiplier(formData.frequency)} = ${formattedEffect} timer`
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="found-hour-jobnumber">Projekt</label>
            <select
              className="form-control"
              name="found_hour[jobnumber_id]"
              value={formData.jobnumber_id}
              onChange={e => setFormData({ ...formData, jobnumber_id: e.target.value })}
            >
              {availableJobnumbers.map(jobnumber => (
                <option key={jobnumber.id} value={jobnumber.id}>
                  {jobnumber.job_id} - {jobnumber.title}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="found-hour-department">Afdeling</label>
            <select
              className="form-control"
              name="found_hour[department_id]"
              value={formData.department_id}
              onChange={e => setFormData({ ...formData, department_id: e.target.value })}
            >
              {availableDepartments.map(department => (
                <option key={department.id} value={department.id}>
                  {department.title}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-sm-6 form-group">
          <label htmlFor="found-hour-date">Dato</label>
          <input
            id="found-hour-date"
            required
            type="date"
            className="form-control col-xs-12"
            value={formData.date}
            onChange={e => setFormData({ ...formData, date: e.target.value })}
            name="found_hour[date]"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 form-group">
          <label htmlFor="found-hour-saved-type">Sparet eller faktureret</label>
          <select
            id="found-hour-saved-type"
            className="form-control"
            value={formData.saved_type}
            onChange={e => setFormData({ ...formData, saved_type: e.target.value })}
            name="found_hour[saved_type]"
          >
            <option value="saved">Sparet</option>
            <option value="invoiced">Faktureret</option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-12 form-group">
          <label htmlFor="found-hour-frequency">Hyppighed</label>
          <select
            id="found-hour-frequency"
            className="form-control"
            value={formData.frequency}
            onChange={e => setFormData({ ...formData, frequency: e.target.value })}
            name="found_hour[frequency]"
          >
            <option value="once">Enkeltstående</option>
            <option value="daily">Dagligt</option>
            <option value="weekly">Ugentligt</option>
            <option value="monthly">Månedligt</option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="form-group pr-1 time-picker-field">
            <label>Tid</label>
            <HoursPicker hours={hours} setHours={h => setFormData({ ...formData, minutes: h * 60 + minutes })} name="found_hour[hours]" />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group pr-1 time-picker-field">
            <label>&nbsp;</label>
            <MinutesPicker
              minutes={minutes}
              setMinutes={m => setFormData({ ...formData, minutes: hours * 60 + m })}
              name="found_hour[only_minutes]"
            />
            <input className="invisible-field" type="number" onChange={() => {}} value={hours * 60 + minutes} min={5} />
          </div>
          <input type="hidden" name="found_hour[minutes]" value={hours * 60 + minutes} min={5} />
        </div>
      </div>

      <div className="row">
        <div className="col-12 form-group">
          <label htmlFor="found-hour-description">Hvordan har du fundet en time</label>
          <textarea
            id="found-hour-description"
            name="found_hour[description]"
            className="form-control"
            value={formData.description}
            onChange={e => setFormData({ ...formData, description: e.target.value })}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 form-group">
          {effect > 0 && (
            <>
              <span>Effect på årlig basis: {formattedEffect} </span>
              <small>({effectCalculation})</small>
            </>
          )}
        </div>
      </div>
    </>
  )
}
