import * as React from 'react'
import { useState } from 'react'
import { format, parseISO, isSameDay } from 'date-fns'
import { da } from 'date-fns/locale'
import { resources_warehouses_path } from '../../routes'
import DatePickerButton from '../shared/DatePickerButton'

export default function ResourcesWarehousesDatepicker({ initialDate }) {
  const initialSelected = parseISO(initialDate)
  const [selected, setSelected] = useState(initialSelected || new Date())
  const handleDateChange = date => {
    setSelected(date)
    if (!isSameDay(date, initialSelected)) {
      window.location.href = resources_warehouses_path({ date: format(date, 'yyyy-MM-dd') })
    }
  }

  return (
    <DatePickerButton
      buttonLabel={format(selected, 'eeee, PPP', { locale: da })}
      buttonClass="btn btn-outline-secondary w-100"
      date={selected}
      setDate={handleDateChange}
    />
  )
}
