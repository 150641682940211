import * as React from 'react'
import { flexRender, Table } from '@tanstack/react-table'

export default function Rows({ table }: { table: Table<any> }) {
  const { rows } = table.getRowModel()
  return (
    <>
      {rows.map(row => (
        <tr key={row.id}>
          {row.getVisibleCells().map(cell => (
            <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
          ))}
        </tr>
      ))}
    </>
  )
}
