import _ from 'lodash'

export default {
  isMobileView () {
    return window.innerWidth <= 480
  },

  cumulativeOffset (element) {
    let top = 0
    let left = 0
    do {
      top += element.offsetTop || 0
      left += element.offsetLeft || 0
      element = element.offsetParent
    } while (element)

    return {
      top: top,
      left: left
    }
  },

  getVerticalMapPosition () {
    let mapPosition = this.cumulativeOffset(document.getElementById('map'))
    return mapPosition.top - (this.isMobileView() ? 30 : 70)
  }
}
