import _ from 'lodash'
import Image from './Image'

const Tag = function (attrs = {}) {
  this.id = attrs.id
  this.name = attrs.name
  this.description = attrs.description
  this.image = attrs.image ? new Image(attrs.image) : null
  this.amount = 0
}

_.extend(Tag.prototype, {
})

export default Tag
