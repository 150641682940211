import * as React from 'react'
import { DepartmentShape, EmailTemplateShape } from '../../../api/api_data_types'
import Select from 'react-select'
import ErrorMessage from '../../job_plannings/list_app/ErrorMessage'
import useReminderFormController from '../../../hooks/useReminderFormController'

interface ReminderFormProps {
  department: DepartmentShape
  templates: EmailTemplateShape[]
  close: () => void
}

export default function ReminderForm({ department, templates, close }: ReminderFormProps) {
  const { formData, setFormData, mutation, currentTemplate, setCurrentTemplate } = useReminderFormController(department, templates)

  const recipients = formData.recipients.map(r => {
    const user = department.managers.find(m => m.id === r)
    return { value: user.id, label: user.username }
  })

  return (
    <div className="p-1">
      <form
        onSubmit={e => {
          e.preventDefault()
          mutation.mutate(formData)
        }}
      >
        <div className="popup-body">
          {mutation.isSuccess && (
            <div className="alert alert-success text-left">
              {mutation.data?.message}
            </div>
          )}
          {!mutation.isSuccess && (
            <>
              <div className="form-group">
                <label htmlFor="reminder-emails">Modtagere</label>
                <Select
                  id="reminder-emails"
                  isMulti
                  value={recipients}
                  onChange={val => setFormData({ ...formData, recipients: val.map(v => v.value) })}
                  options={department.managers.map(user => ({ value: user.id, label: user.username }))}
                />
              </div>
              <div className="form-group">
                <label htmlFor="reminder-template">Skabelon</label>
                <select
                  className="form-control"
                  id="reminder-template"
                  value={currentTemplate?.id || ''}
                  onChange={e => {
                    const template = templates.find(t => t.id === parseInt(e.target.value, 10))
                    if (template) {
                      setCurrentTemplate(template)
                    }
                  }}
                >
                  {templates.map(template => (
                    <option key={template.id} value={template.id}>
                      {template.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="reminder-subject">Emne</label>
                <input
                  type="text"
                  className="form-control"
                  id="reminder-subject"
                  value={formData.subject || ''}
                  required
                  onChange={e => setFormData({ ...formData, subject: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="reminder-text">Påmindelsestekst</label>
                <textarea
                  className="form-control"
                  id="reminder-text"
                  rows={4}
                  required
                  value={formData.body || ''}
                  onChange={e => setFormData({ ...formData, body: e.target.value })}
                />
              </div>
            </>
          )}
        </div>
        <div className="popup-actions">
          <ErrorMessage isError={mutation.isError} error={mutation.error} />
          <button type="button" className="btn btn-outline-secondary" onClick={close}>
            Luk
          </button>
          <button type="submit" className="btn btn-primary" disabled={mutation.isLoading || mutation.isError || mutation.isSuccess}>
            {mutation.isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />}
            {!mutation.isLoading && 'Send påmindelse'}
          </button>
        </div>
      </form>
    </div>
  )
}
