import _ from 'lodash'
import I18nStore from '../stores/I18nStore'

const SEARCHABLE_FIELDS = ['name', 'email', 'city', 'getTagNames']
const OR_SYMBOL = '+'

const Filter = function () {
  this.searchText = ''
  this.categories = []
  this.priorityIds = []
  this.tagIds = []
}

_.extend(Filter.prototype, {
  byAll (vendor) {
    return this.byCategory(vendor) &&
      this.byPriorities(vendor) &&
      this.byTags(vendor) &&
      this.bySearchText(vendor)
  },

  byAllExceptPriorities (vendor) {
    return this.byCategory(vendor) &&
      this.byTags(vendor) &&
      this.bySearchText(vendor)
  },

  byAllExceptCategories (vendor) {
    return this.byPriorities(vendor) &&
      this.byTags(vendor) &&
      this.bySearchText(vendor)
  },

  byCategory (vendor) {
    if (!this.categories.length) { return true }
    let categoryIds = _.map(this.categories, (c) => c.id)
    return _.some(vendor.categories, (c) => _.includes(categoryIds, c.id))
  },

  byPriorities (vendor) {
    if (!this.priorityIds.length) { return true }
    return vendor.priority && _.includes(this.priorityIds, vendor.priority.id)
  },

  byTags (vendor) {
    if (!this.tagIds.length) { return true }
    return _.every(this.tagIds, (tagId) => {
      return _.includes(vendor.getTagIds(), tagId)
    })
  },

  bySearchText (vendor) {
    let searchText = _.trim(this.searchText).toLowerCase()
    if (!searchText) { return true }
    searchText = this.needToSeparateSearchText() ? this.separateSearchText() : [searchText]
    return _.some(searchText, (text) => {
      return _.some(SEARCHABLE_FIELDS, (field) => {
        let vendorFieldValue = _.trim(this.getSearchableFieldValue(vendor, field)).toLowerCase()
        return vendorFieldValue.indexOf(text) !== -1
      })
    })
  },

  getSearchableFieldValue (vendor, fieldName) {
    return _.isFunction(vendor[fieldName]) ? vendor[fieldName]() : vendor[fieldName]
  },

  needToSeparateSearchText () {
    return this.searchText.indexOf(OR_SYMBOL) !== -1
  },

  separateSearchText () {
    return _.reduce(this.searchText.split(OR_SYMBOL), (result, text) => {
      let t = _.trim(text)
      if (t) {
        result.push(t)
      }
      return result
    }, [])
  },

  getDefaultPriority (prioritiesMap) {
    return  _.find(prioritiesMap, (p) => {
      return _.includes(I18nStore.getAllPrioritized(), p.name)
    })
  }
})

export default Filter
