import * as React from 'react'
import { ColumnDef, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { JobPlanningLineShape } from '../../api/api_data_types'
import PlacementField from '../../components/job_plannings/complete_popup_button/PlacementField'
import CompleteButton from '../../components/job_plannings/complete_popup_button/CompleteButton'
import { format } from 'date-fns'

export default function useCompleteJobPlanningTable(
  jobPlannings: JobPlanningLineShape[],
  reload: () => void,
  setApiError: (error: string) => void
) {
  const columns: ColumnDef<JobPlanningLineShape>[] = React.useMemo(
    () => [
      {
        header: 'Sagsopgnr.',
        accessorKey: 'task_title',
        filterFn: 'includesString',
        cell: info => info.getValue() || null,
      },
      {
        header: 'Kunde',
        accessorKey: 'jobnumber.customer_title',
        filterFn: 'includesString',
        cell: info => info.getValue() || null,
      },
      {
        header: 'Stadiebeskrivelse',
        accessorKey: 'description',
        filterFn: 'includesString',
        cell: info => info.getValue() || null,
      },
      {
        header: 'Slutdato',
        accessorFn: o => {
          return o.finished_date ? format(Date.parse(o.finished_date), 'dd-MM-yyyy') : null
        },
        filterFn: 'includesString',
        cell: info => info.getValue() || null,
      },
      {
        header: 'Timer',
        accessorFn: o => {
          const minutes = Math.round(parseFloat(o.quantity) * 60)
          return `${Math.floor(minutes / 60)}:${(minutes % 60).toString().padStart(2, '0')}`
        },
        filterFn: 'includesString',
        cell: info => info.getValue() || null,
      },
      {
        header: 'Placering',
        accessorKey: 'placement',
        filterFn: 'includesString',
        enableSorting: false,
        cell: ({ cell, row }) => {
          return <PlacementField jobPlanningKey={row.original.key} placement={row.original.placement} reload={reload} />
        },
      },
      {
        header: 'Bemærkning',
        accessorKey: 'comment',
        filterFn: 'includesString',
        cell: info => info.getValue() || null,
      },
      {
        header: 'Actions',
        accessorKey: 'key',
        filterFn: 'includesString',
        enableSorting: false,
        cell: ({ cell, row }) => {
          return <CompleteButton jobPlanning={row.original} reload={reload} setApiError={setApiError} />
        },
      },
    ],
    [jobPlannings]
  )

  const [columnFilters, setColumnFilters] = React.useState([])

  const table = useReactTable({
    data: jobPlannings,
    columns,
    state: {
      columnFilters,
    },
    initialState: {
      sorting: [{ id: 'task_title', desc: false }],
    },
    onColumnFiltersChange: setColumnFilters,
    enableColumnFilters: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return { table }
}
