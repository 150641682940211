import * as React from 'react'
import { week_list_path } from '../../routes'
import DepartmentsPicker from './DepartmentsPicker'
import DatePickerButton from '../shared/DatePickerButton'
import { format, parseISO } from 'date-fns'
import { da } from 'date-fns/locale'

interface FilterAndExportRowProps {
  startDate: string
  endDate: string
  availableDepartments: any[]
  selectedDepartmentIds: number[]
}

export default function FilterAndExportRow(props: FilterAndExportRowProps) {
  const { startDate, endDate, availableDepartments, selectedDepartmentIds } = props
  const exportUrl = week_list_path({
    start_date: startDate,
    end_date: endDate,
    department_ids: selectedDepartmentIds,
    format: 'xlsx',
  })
  const start = parseISO(startDate)
  const end = parseISO(endDate)
  return (
    <div className="d-flex mb-3 flex-wrap" style={{ columnGap: '2rem' }}>
      <div className="flex-grow-1 mb-2">
        <DepartmentsPicker
          startDate={startDate}
          endDate={endDate}
          availableDepartments={availableDepartments}
          selectedDepartmentIds={selectedDepartmentIds}
        />
      </div>

      <div className="mb-2">
        <div className="d-flex">
          <DatePickerButton
            buttonLabel={format(start, 'PPP', { locale: da })}
            buttonClass="btn btn-outline-secondary col-12 text-nowrap"
            date={start}
            setDate={date => {
              if (date !== start) {
                const urlOptions = {
                  end_date: format(end, 'yyyy-MM-dd'),
                  start_date: format(date, 'yyyy-MM-dd'),
                  department_ids: selectedDepartmentIds,
                }
                window.location.href = week_list_path(urlOptions)
              }
            }}
          />
          <div className="p-1 px-3">&mdash;</div>
          <DatePickerButton
            buttonLabel={format(end, 'PPP', { locale: da })}
            buttonClass="btn btn-outline-secondary col-12 text-nowrap"
            date={end}
            setDate={date => {
              if (date !== end) {
                const urlOptions = {
                  end_date: format(date, 'yyyy-MM-dd'),
                  start_date: format(start, 'yyyy-MM-dd'),
                  department_ids: selectedDepartmentIds,
                }
                window.location.href = week_list_path(urlOptions)
              }
            }}
          />
        </div>
      </div>

      <div className="ml-auto">
        <div className="d-flex">
          <a className="btn btn-outline-secondary text-nowrap" href={exportUrl}>
            <i className="fa fa-download mr-1" />
            Download excel
          </a>
        </div>
      </div>
    </div>
  )
}
